<template>
  <cw-page
    icon="done"
    class="cw-business-loan-pending"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>
    <v-row>
      <v-col
        class="
          cw-border-container
          cw-border-container--primary
        "
        cols="12"
        md="8"
      >
        <p>
          <translate>
            For your part, the loan application is ready and waiting for action from the signatories
            attached to the application.
          </translate>
        </p>

        <p>
          <b><translate :translate-params="{ expirationDate }">
            The loan application must be signed by %{ expirationDate }
          </translate></b>.
          <translate>
            The loan will be paid into the Company's account once the signatories specified in the
            application have confirmed their loan application.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwBusinessLoanPending',

  computed: {
    ...mapGetters({
      expirationDate: 'application/expirationDate',
    }),
  },
};
</script>
